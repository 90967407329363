import React, { useContext, useMemo } from 'react'
import { pimApi, activityApi } from 'shared/api'
import { useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'
import {
  getOptions
} from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { Autocomplete, Box, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { SelectWrapper, StyledFilterContainer , FilterWrapper } from 'shared/ui/components/DataFilters/styled'
import { debouncedSearch } from 'shared/ui/components/Table/ui/TableSearch/TableSearch'

import { ActivitiesListContext, IActivitiesListContext } from '../../../model/ActivityContext'

const involvementOptions = [
  {
    value: true,
    label: 'Участвуют'
  },
  {
    value: false,
    label: 'Не участвуют'
  },
]

export const TableFilters = () => {
  const { handleEditParams, activityParams } = useContext(ActivitiesListContext) as IActivitiesListContext

  const { data: marketplaceListQuery } = pimApi.dictionaries.useMarketplaceList().marketplaceListQuery
  const { companyListQuery } = pimApi.dictionaries.useCompanyList()
  const { companyMarketplaceListQuery } =
    useCompanyMarketplaceList(activityParams.marketplaceId || null, activityParams.companyId || null)
  const { data: activityTypesListQuery } = activityApi.useActivityTypesQuery()
  const { data: campaignStatusListQuery } = activityApi.useCampaignStatusQuery()
  
  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery])

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data])

  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const activityTypesOptions = useMemo(
    () => activityTypesListQuery?.map(getOptions) || [],
    [activityTypesListQuery]
  )

  const campaignStatusOptions = useMemo(
    () => campaignStatusListQuery?.map(getOptions) || [],
    [campaignStatusListQuery]
  )

  return (
    <FilterWrapper style={{ paddingBottom: '16px' }}>
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        defaultValue={activityParams.searchString}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        }}
        onChange={(e) => {
          debouncedSearch(() => handleEditParams({ searchString: e.target.value, page: 0 }))
        }}
        InputProps={{
          endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
        }}/>
      <StyledFilterContainer>
        <SelectWrapper width={200}>
          <Autocomplete
            options={activityTypesOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            value={activityTypesOptions.find(el => (activityParams.typeId === el.value)) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              if (value === null) {
                handleEditParams({ typeId: undefined, page: 0 })
              } else {
                handleEditParams({ typeId: value?.value, page: 0 })
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            disableClearable={!activityParams.typeId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Вид кампании"/>
            )}
          />
        </SelectWrapper>
        <SelectWrapper width={200}>
          <Autocomplete
            options={campaignStatusOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            value={campaignStatusOptions.find(el => (activityParams.campaignStatus === el.value)) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              if (value === null) {
                handleEditParams({ campaignStatus: undefined, page: 0 })
              } else {
                handleEditParams({ campaignStatus: value?.value, page: 0 })
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            disableClearable={!activityParams.campaignStatus}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Статус кампании"/>
            )}
          />
        </SelectWrapper>
        <SelectWrapper width={130}>
          <Autocomplete
            options={involvementOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            value={involvementOptions.find(el => (activityParams.involvement === el.value)) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              if (value === null) {
                handleEditParams({ involvement: undefined, page: 0 })
              } else {
                handleEditParams({ involvement: value?.value, page: 0 })
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={String(option.value)}>
                {option.label}
              </Box>
            )}
            disableClearable={!activityParams.campaignStatus}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Участие"/>
            )}
          />
        </SelectWrapper>
        <SelectWrapper width={200}>
          <Autocomplete
            options={marketplaceOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            value={marketplaceOptions.find(el => (activityParams.marketplaceId === el.value)) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              if (value === null) {
                handleEditParams({ marketplaceId: undefined, companymarketplaceId: undefined, page: 0 })
              } else {
                handleEditParams({ marketplaceId: value?.value, page: 0 })
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            disableClearable={!activityParams.marketplaceId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Маркетплейс"/>
            )}
          />
        </SelectWrapper>
        <SelectWrapper width={200}>
          <Autocomplete
            options={companyOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            value={companyOptions.find(el => (activityParams.companyId === el.value)) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              if (value === null) {
                handleEditParams({ companyId: undefined, companymarketplaceId: undefined, page: 0 })
              } else {
                handleEditParams({ companyId: value?.value, page: 0, })
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            disableClearable={!activityParams.companyId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Продавец"/>
            )}
          />
        </SelectWrapper>
        <SelectWrapper width={200}>
          <Autocomplete
            options={companymarketplaceOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disabled={companymarketplaceOptions.length === 0}
            disableCloseOnSelect={true}
            size="small"
            value={companymarketplaceOptions.find(el => (activityParams.companymarketplaceId === el.value))  || { label: '', value: 0 }}
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              handleEditParams({ companymarketplaceId: value?.value || undefined, page: 0 })
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            disableClearable={!activityParams.companymarketplaceId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Кабинет"/>
            )}
          />
        </SelectWrapper>
      </StyledFilterContainer>
    </FilterWrapper>
  )
}

const isOptionEqual = (opt, val) => opt?.value === val?.value