import { Box, Typography } from '@mui/material'
import { round } from 'shared/lib/utils/round'
import React from 'react'
import { AnalyticsOrders, AnalyticsStocks } from 'shared/api/analytics'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { convertDate } from 'shared/lib/utils/DateAppearanceSettings'
import { getDatesLength } from 'shared/lib/utils/getDatesLength'
import { getCalendarReadableDateTime } from 'shared/lib/utils'
import { subtractDays } from 'shared/lib/utils/subtractDays'

export const ChartDetails = ({ 
  visualRange,
  units,
  ordersData,
  stocksData 
}: { visualRange: any, ordersData: AnalyticsOrders, stocksData: AnalyticsStocks, units: string }) => {

  const startDateString = visualRange.startValue ?
    getCalendarReadableDateTime(new Date(visualRange.startValue)).split('T')[0] :
    getCalendarReadableDateTime(new Date(visualRange[0] || ordersData.dateFrom)).split('T')[0]

  const endDateString = visualRange.endValue ?
    getCalendarReadableDateTime(new Date(visualRange.endValue)).split('T')[0] :
    getCalendarReadableDateTime(new Date(visualRange[1] || ordersData.dateTo)).split('T')[0]

  const datesLength = getDatesLength(new Date(startDateString), new Date(endDateString))

  const diffStartString = subtractDays(new Date(startDateString), datesLength)
  const diffEndString = subtractDays(new Date(endDateString), datesLength)
  
  const prevTotalOrdersUnits = diffStartString >= ordersData.dateFrom ?
    ordersData.data.
      filter(el => el.orderDate >= diffStartString && el.orderDate <= diffEndString).
      reduce((sum, elem) => sum + elem[units], 0) : undefined
  const prevIntervalOrdersItems = diffStartString >= ordersData.dateFrom ?
    ordersData.data.
      filter(el => el.orderDate >= diffStartString && el.orderDate <= diffEndString).
      reduce((sum, elem) => sum + elem.items, 0) : undefined
  const prevIntervalOrdersCurrency = diffStartString >= ordersData.dateFrom ?
    ordersData.data.
      filter(el => el.orderDate >= diffStartString && el.orderDate <= diffEndString).
      reduce((sum, elem) => sum + elem.currency, 0) : undefined
  const prevIntervalNumberOfOrders = diffStartString >= ordersData.dateFrom ?
    ordersData.data.
      filter(el => el.orderDate >= diffStartString && el.orderDate <= diffEndString).
      reduce((sum, elem) => sum + elem.numberOfOrders, 0) : undefined

  const totalOrdersUnits = ordersData.data.
    filter(el => el.orderDate >= startDateString && el.orderDate <= endDateString).
    reduce((sum, elem) => sum + elem[units], 0)
  
  const intervalOrdersItems = ordersData.data.
    filter(el => el.orderDate >= startDateString && el.orderDate <= endDateString).
    reduce((sum, elem) => sum + elem.items, 0)
  const intervalOrdersCurrency = ordersData.data.
    filter(el => el.orderDate >= startDateString && el.orderDate <= endDateString).
    reduce((sum, elem) => sum + elem.currency, 0)
  const intervalStocks = stocksData.data.
    filter(el => el.stocksDate === endDateString).
    reduce((sum, elem) => sum + elem.available, 0)
  const intervalNumberOfOrders = ordersData.data.
    filter(el => el.orderDate >= startDateString && el.orderDate <= endDateString).
    reduce((sum, elem) => sum + elem.numberOfOrders, 0)

  const unitsOrdersDelta = prevTotalOrdersUnits ? totalOrdersUnits - prevTotalOrdersUnits : undefined
  const averagePriceDelta = (prevIntervalOrdersItems && prevIntervalOrdersCurrency) ?
    (intervalOrdersCurrency / intervalOrdersItems) - (prevIntervalOrdersCurrency / prevIntervalOrdersItems) : undefined
  const numberOfOrdersDelta = (prevIntervalOrdersItems && prevIntervalNumberOfOrders && intervalOrdersItems && intervalNumberOfOrders) ?
    (intervalOrdersItems / intervalNumberOfOrders) - (prevIntervalOrdersItems / prevIntervalNumberOfOrders) : undefined
  // неизвестно, как отображать разницу остатков
  // const stocksDelta = intervalStocks - prevIntervalStocks
  return (
    <Box sx={{ height: '88px', display: 'flex', flexDirection: 'row', gap: '50px' }}>
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Заказы
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round(totalOrdersUnits, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {unitsOrdersDelta &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px'
          }}>
            <CallMadeIcon sx={{
              fontSize: 14,
              color: unitsOrdersDelta > 0 ?' #757575' : '#F50057',
              rotate: unitsOrdersDelta > 0 ? '' : '90deg'
            }}/>
            <span style={{ fontSize: 12, color: unitsOrdersDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
              {Math.abs(unitsOrdersDelta).toLocaleString() || 0}
            </span>
          </div>
          }
        </Box>
      </Box>
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          {`Остатки на ${convertDate(endDateString.split('T')[0])}`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {intervalStocks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {/* <div style={{ */}
          {/*  display: 'flex', */}
          {/*  alignItems: 'center', */}
          {/*  marginLeft: '8px' */}
          {/* }}> */}
          {/*  <CallMadeIcon sx={{ */}
          {/*    fontSize: 14, */}
          {/*    color: stocksDelta > 0 ?' #757575' : '#F50057', */}
          {/*    rotate: stocksDelta > 0 ? '' : '90deg' */}
          {/*  }}/> */}
          {/*  <span style={{ fontSize: 12, color: stocksDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}> */}
          {/*    {Math.abs(stocksDelta).toLocaleString() || 0} */}
          {/*  </span> */}
          {/* </div> */}
        </Box>
      </Box>
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Ср. цена за ед. продукта
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round((intervalOrdersCurrency / intervalOrdersItems) || '0', 0)} ₽
          </Typography>
          {averagePriceDelta ?
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '8px'
            }}>
              <CallMadeIcon sx={{
                fontSize: 14,
                color: averagePriceDelta > 0 ?' #757575' : '#F50057',
                rotate: averagePriceDelta > 0 ? '' : '90deg'
              }}/>
              <span style={{ fontSize: 12, color: averagePriceDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
                {Math.abs(Number(Math.round(averagePriceDelta || 0))).toLocaleString()}
              </span>
            </div>
            :
            <></>
          }
        </Box>
      </Box>
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Кол-во продуктов в корзине
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round((intervalOrdersItems / intervalNumberOfOrders) || '0', 2)}
          </Typography>
          {numberOfOrdersDelta ?
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '8px'
            }}>
              <CallMadeIcon sx={{
                fontSize: 14,
                color: numberOfOrdersDelta > 0 ?' #757575' : '#F50057',
                rotate: numberOfOrdersDelta > 0 ? '' : '90deg'
              }}/>
              <span style={{ fontSize: 12, color: numberOfOrdersDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
                {Math.abs(numberOfOrdersDelta).toLocaleString() || 0}
              </span>
            </div>
            :
            <></>
          }
        </Box>
      </Box>
      {/* <Box */}
      {/*  sx={{ */}
      {/*    width: '206px', */}
      {/*    height: '88px', */}
      {/*    display: 'flex', */}
      {/*    flexDirection: 'column', */}
      {/*    justifyContent: 'center', */}
      {/*    paddingTop: '16px', */}
      {/*    paddingLeft: '16px', */}
      {/*    paddingRight: '16px' */}
      {/*  }}> */}
      {/*  <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}> */}
      {/*    Процент выкупа */}
      {/*  </Typography> */}
      {/*  <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}> */}
      {/*    {round((totalOrdersItems / ordersBuyoutSum) * 100 || '0', 2)}% */}
      {/*  </Typography> */}
      {/* </Box> */}
    </Box>
  )
}
