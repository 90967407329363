import React, { useContext } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { generatePath } from 'react-router'
import { ANALYTICS_EDIT_ACTIVITY } from 'shared/config'
import { format } from 'date-fns'
import { CircularProgress } from '@mui/material'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isNil } from 'shared/lib/checkers'

import { ActivityPagination } from '../ActivityPagination/ActivityPagination'
import { PlugActivityPage } from '../PlugActivityPage/PlugActivityPage'
import { ActivitiesListContext } from '../../../model/ActivityContext'
import { StyledItem, StyledLink, StyledLoader } from '../../styled'

export const ActivityTable = () => {
  const { activityQuery } = useContext(ActivitiesListContext) as any

  const getActivityPath = (id: UniqueId | string) => generatePath(ANALYTICS_EDIT_ACTIVITY, { id })

  if (isEmptyArray(activityQuery.data?.info) && !activityQuery.isLoading) return <PlugActivityPage/>

  if (isNil(activityQuery.data) && activityQuery.isLoading) {
    return (
      <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={60}/>
      </div>
    )
  }
  return (
    <div>
      {activityQuery.isFetching && <StyledLoader size={60}/>}
      { activityQuery?.data?.info?.map(el => (
        <StyledLink to={getActivityPath(el.id)}>
          <StyledItem
            old={new Date() > new Date(el.toDate || '')}
            future={new Date() < new Date(el.fromDate)}>

            <div className="date-container">
              <span>{el.toDate ? format(new Date(el.toDate), 'dd.MM.yyyy HH:mm') : '–'}</span>
              <span>{format(new Date(el.fromDate), 'dd.MM.yyyy HH:mm')}</span>
            </div>
            <div className="divider"/>
            <div className="activity-main-info">
              <div className="activity-info-row">
                <div className="left-side">
                  {el.typeName && <span>{el.typeName}</span>}
                  { el.marketplaceName && <span>{el.marketplaceName}</span> }
                </div>
                <div className="right-side">
                  { el.companyName && <span>{el.companyName}</span> }
                </div>
              </div>
              <span className="activity-name" title={el.name}>{el.name}</span>
            </div>
            <div className="activity-count">
              <span>Продукты</span>
              <span>{el.countproduct}</span>
            </div>
            <KeyboardArrowRightIcon className="arrow-icon"/>
          </StyledItem>
        </StyledLink>
      ))}
      <ActivityPagination/>
    </div>
  )
}