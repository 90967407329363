import { Box, Typography } from '@mui/material'
import { round } from 'shared/lib/utils/round'
import React, { useEffect } from 'react'
import { AnalyticsDataSale, AnalyticsOrders } from 'shared/api/analytics'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { getDatesLength } from 'shared/lib/utils/getDatesLength'
import { getCalendarReadableDateTime } from 'shared/lib/utils'
import { subtractDays } from 'shared/lib/utils/subtractDays'

import { StyledLegend, StyledLegendContainer } from './styled'

export const ChartDetails = ({
  visualRange,
  ordersData,
  units,
  hiddenSeries,
  setHiddenSeries,
  salesData
}: {
  visualRange: any,
  ordersData: AnalyticsOrders,
  setHiddenSeries: (e) => void,
  units: string
  salesData: AnalyticsDataSale,
  hiddenSeries: any
}) => {

  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'o' && event.target.value === undefined) {
        event.preventDefault()

        LegengChangeHandle('orders')
      }

      if (event.key === 's' && event.target.value === undefined) {
        event.preventDefault()

        LegengChangeHandle('sales')
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const LegengChangeHandle = (value) => {
    setHiddenSeries(prev => {
      const { ...rest } = prev
      rest[value] = !prev[value]
      return rest
    })
  }

  const startDateString = visualRange.startValue ?
    getCalendarReadableDateTime(new Date(visualRange.startValue)).split('T')[0] :
    getCalendarReadableDateTime(new Date(visualRange[0] || ordersData.dateFrom)).split('T')[0]

  const endDateString = visualRange.endValue ?
    getCalendarReadableDateTime(new Date(visualRange.endValue)).split('T')[0] :
    getCalendarReadableDateTime(new Date(visualRange[1] || ordersData.dateTo)).split('T')[0]

  const datesLength = getDatesLength(new Date(startDateString), new Date(endDateString))

  const diffStartString = subtractDays(new Date(startDateString), datesLength)
  const diffEndString = subtractDays(new Date(endDateString), datesLength)

  const totalOrders = ordersData.data.
    filter(el => el.orderDate >= startDateString && el.orderDate <= endDateString).
    reduce((sum, elem) => sum + elem[units], 0)
  const totalSales = salesData.data.
    filter(el => el.saleDate >= startDateString && el.saleDate <= endDateString).
    reduce((sum, elem) => sum + elem.quantity, 0)
  

  const prevTotalOrders = diffStartString >= ordersData.dateFrom ?
    ordersData.data.
      filter(el => el.orderDate >= diffStartString && el.orderDate <= diffEndString).
      reduce((sum, elem) => sum + elem[units], 0) : undefined

  const prevTotalSales = diffStartString >= ordersData.dateFrom ?
    salesData.data.
      filter(el => el.saleDate >= diffStartString && el.saleDate <= diffEndString).
      reduce((sum, elem) => sum + elem.quantity, 0) : undefined

  const unitsOrdersDelta = prevTotalOrders ? totalOrders - prevTotalOrders : undefined
  const unitsSalesDelta = prevTotalSales ? totalSales - prevTotalSales : undefined

  // const totalReturns = totalOrders - totalSales
  // const prevTotalReturns = (prevTotalOrders && prevTotalSales) ? prevTotalOrders - prevTotalSales : undefined
  // const totalReturnsDelta = prevTotalReturns ? Math.abs(totalReturns) - Math.abs(prevTotalReturns) : undefined
  return (
    <Box sx={{ height: '88px', display: 'flex', flexDirection: 'row', gap: '48px' }}>
      <Box
        sx={{
          width: '200px',
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Заказы
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round(totalOrders, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {unitsOrdersDelta &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px'
          }}>
            <CallMadeIcon sx={{
              fontSize: 14,
              color: unitsOrdersDelta > 0 ?' #757575' : '#F50057',
              rotate: unitsOrdersDelta > 0 ? '' : '90deg'
            }}/>
            <span style={{ fontSize: 12, color: unitsOrdersDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
              {Math.abs(unitsOrdersDelta).toLocaleString() || 0}
            </span>
          </div>
          }
        </Box>
      </Box>
      <Box
        sx={{
          width: '200px',
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Выкупы
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round(totalSales, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {unitsSalesDelta &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px'
          }}>
            <CallMadeIcon sx={{
              fontSize: 14,
              color: unitsSalesDelta > 0 ?' #757575' : '#F50057',
              rotate: unitsSalesDelta > 0 ? '' : '90deg'
            }}/>
            <span style={{ fontSize: 12, color: unitsSalesDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
              {Math.abs(unitsSalesDelta).toLocaleString() || 0}
            </span>
          </div>
          }
        </Box>
      </Box>
      <Box
        sx={{
          width: '202px',
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
        }}>
        {/* До выяснения обстоятельств */}
        {/* <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}> */}
        {/*  Возвраты */}
        {/* </Typography> */}
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}> */}
        {/*  <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}> */}
        {/*    {round(totalReturns, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
        {/*  </Typography> */}
        {/*  {totalReturnsDelta && */}
        {/*  <div style={{ */}
        {/*    display: 'flex', */}
        {/*    alignItems: 'center', */}
        {/*    marginLeft: '8px' */}
        {/*  }}> */}
        {/*    <CallMadeIcon sx={{ */}
        {/*      fontSize: 14, */}
        {/*      color: totalReturnsDelta < 0 ?' #757575' : '#F50057', */}
        {/*      rotate: totalReturnsDelta > 0 ? '' : '90deg' */}
        {/*    }}/> */}
        {/*    <span style={{ fontSize: 12, color: totalReturnsDelta < 0 ?' #757575' : '#F50057', fontWeight: '500' }}> */}
        {/*      {Math.abs(totalReturnsDelta).toLocaleString() || 0} */}
        {/*    </span> */}
        {/*  </div> */}
        {/*  } */}
        {/* </Box> */}
      </Box>
      <StyledLegendContainer>
        <StyledLegend onClick={() => {LegengChangeHandle('orders')}}>
          <Box sx={{ width: '16px', height: '16px', borderRadius: '4px', backgroundColor: hiddenSeries.orders ? '#4CAF50' : '#E0E0E0' }}/>
          <Typography sx={{ color: hiddenSeries.orders ? 'rgba(0, 0, 0, 0.54)' : '#E0E0E0' }}>Заказы</Typography>
        </StyledLegend>
        <StyledLegend sx={{ cursor: 'pointer' }} onClick={() => {LegengChangeHandle('sales')}}>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              borderRadius: '4px',
              backgroundColor: hiddenSeries.sales ? 'rgba(33, 150, 243, 0.16)' : '#E0E0E0' }}/>
          <Typography sx={{ color: hiddenSeries.sales ? 'rgba(0, 0, 0, 0.54)' : '#E0E0E0' }}>Выкупы</Typography>
        </StyledLegend>
      </StyledLegendContainer>
    </Box>
  )
}
