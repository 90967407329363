import React, { useContext } from 'react'
import { TablePagination } from '@mui/material'
import { stringToNum } from 'shared/lib/utils/stringToNum'

import { pagingPanelLocale } from '../../../lib/localization'
import { ActivitiesListContext, IActivitiesListContext } from '../../../model/ActivityContext'

export const ActivityPagination = () => {
  const { activityParams, activityQuery, pageSizes, handleEditParams } = useContext(ActivitiesListContext) as IActivitiesListContext
  
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
      <TablePagination
        sx={{ borderBottom: 'none' }}
        count={activityQuery.data?.count || 0}
        onPageChange={(event, page) => {handleEditParams({ page })}}
        onRowsPerPageChange={(event) => {handleEditParams({ pageSize: event.target.value, page: 0 })}}
        page={stringToNum(activityParams.page) || 0}
        rowsPerPageOptions={pageSizes}
        rowsPerPage={stringToNum(activityParams?.pageSize) || 0}
        labelDisplayedRows={pagingPanelLocale.info}
        labelRowsPerPage={pagingPanelLocale.rowsPerPage}
      />
    </div>
  )
}