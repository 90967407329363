import React, { useState } from 'react'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { Button, DialogActions, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import icon from 'assets/images/icons/excel.svg'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import { StyledExportModalContainer } from '../../styled'

type unitsType = 'currency' | 'items' | 'all'
type groupByType = 'day' | 'month' | 'quarter' | 'year' | 'week'
type statsType = 'orders' | 'sales' | 'stocks'

interface ISelectedParams {
  units: unitsType
  groupby: groupByType
  stats: statsType
}

export const FileExportModal = ({ close, accept, unloadingSettings }) => {
  const [ selectedParams, setSelectedParams ] = useState<ISelectedParams>({
    units: unloadingSettings.units, groupby: unloadingSettings.groupby, stats: unloadingSettings.stats
  })

  const handleStocks = () => {
    setSelectedParams(prev => ({ ...prev, groupby: 'day' as groupByType }))}
  
  return (
    <StyledExportModalContainer>
      <DialogTitleWithBtn
        sx={ { fontSize: '20px', padding: '0px' } }
        onClose={ close }>
        Параметры выгрузки
      </DialogTitleWithBtn>

      <div className="radio-group-container">

        <div>
          <span>Тип выгрузки</span>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedParams.stats}
            onChange={(_, value) => setSelectedParams(prev => ({ ...prev, stats: value as statsType }))}
          >
            <FormControlLabel value="orders" control={<Radio />} label="Заказы" />
            <FormControlLabel value="sales" control={<Radio />} label="Продажи" />
            <FormControlLabel value="stocks" control={<Radio onChange={() => handleStocks()} />} label="Остатки" />
          </RadioGroup>
        </div>

        <div>
          <span>Единицы измерения</span>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedParams.units}
            onChange={(_, value) => setSelectedParams(prev => ({ ...prev, units: value as unitsType }))}
          >
            <FormControlLabel value="items" control={<Radio />} label="Штуки" />
            <FormControlLabel value="currency" control={<Radio />} label="Рубли" />
            <FormControlLabel value="all" control={<Radio />} label="Штуки и рубли" />
          </RadioGroup>
        </div>

        <div>
          <span>Детализация по</span>
          <RadioGroup
            className="groupby-radio-buttons-group"
            aria-labelledby="demo-radio-buttons-group-label"
            value={ selectedParams.groupby }
            onChange={ (_, value) => setSelectedParams(prev => ({ ...prev, groupby: value as groupByType })) }
          >

            { selectedParams.stats === 'stocks' ?
              <>
                <FormControlLabel value="day" control={ <Radio defaultChecked={true} /> } label="дням" />
                <FormControlLabel value="week" control={ <Radio disabled={true} /> } label="неделям" />
                <FormControlLabel value="month" control={ <Radio disabled={true} /> } label="месяцам" />
                <FormControlLabel value="quarter" control={ <Radio disabled={true} /> } label="кварталам" />
                <FormControlLabel value="year" control={ <Radio disabled={true} /> } label="годам" />
              </> : <>
                <FormControlLabel value="day" control={ <Radio /> } label="дням" />
                <FormControlLabel value="week" control={ <Radio /> } label="неделям" />
                <FormControlLabel value="month" control={ <Radio /> } label="месяцам" />
                <FormControlLabel value="quarter" control={ <Radio /> } label="кварталам" />
                <FormControlLabel value="year" control={ <Radio /> } label="годам" /></>
            }
          </RadioGroup>
        </div>
      </div>

      <DialogActions sx={ { padding: '0' } }>
        <Button
          variant="text"
          onClick={ close }
        >
          ОТМЕНА
        </Button>
        <Button
          variant="contained"
          onClick={() => accept(selectedParams)}
          startIcon={<ExcelIcon src={icon} alt="excelImg" />}
        >
          ЭКСПОРТ
        </Button>
      </DialogActions>
    </StyledExportModalContainer>
  )
}