import { Box } from '@mui/material'
import styled from 'styled-components'

export const Paper = styled(Box)`
  margin-top: 28px;
  box-shadow: none;
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 16px 24px;

    .header-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.15000000596046448px;
    }
  }
`