import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(100)
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  typeId: yup.number().required('Обязательное поле'),

  fromDate: yup.date()
    .required()
    .typeError('Данные введены неверно')
    .transform(yupTransformEmptyStringInNull),

  toDate: yup.date()
    .required()
    .typeError('Данные введены неверно')
    .transform(yupTransformEmptyStringInNull),
})