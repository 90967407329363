import React, { useEffect, useMemo, useState } from 'react'
import Chart, {
  Series,
  Legend,
  CommonSeriesSettings,
  Point,
  Label,
  Tooltip,
  Tick,
  ArgumentAxis,
  ZoomAndPan,
  ValueAxis,
  Grid, Font, Crosshair, VerticalLine,
} from 'devextreme-react/chart'
import RangeSelector, {
  Size,
  Chart as ChartOptions,
  Margin,
  Label as RangeLabel,
  Scale,
  Behavior,
  SliderMarker, MinorTick, Marker, MinRange
} from 'devextreme-react/range-selector'
import { Box, CircularProgress, Typography } from '@mui/material'
import { AnalyticsStocks } from 'shared/api/analytics'
import styled from 'styled-components'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import { round } from 'shared/lib/utils/round'
import queryString from 'query-string'
import { mergeArrayByKey } from 'shared/lib/utils/mergeArrayByKey'

import { ChartDetails } from './ChartDetails'

import { useChartContext } from '../../../../lib/useChartContext'



const StyledTooltip = styled.div`
    min-height: 100px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 8px 16px;
    margin-top: 30px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 3px rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
`
const StyledLoader = styled(CircularProgress)`
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50%);
  z-index: 1;
`
export const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};`

interface dataType {
  orders: number
  stocks: number
  orderDate: string
}

export const Charts = ({ isLoading }: { isLoading: boolean }) => {
  const { value: context } = useChartContext()
  const { searchObj, ordersData, stocksData } = context

  const [ rangeSelector, setRangeSelector ] =
    useState({
      startValue: searchObj.timelineStart,
      endValue: searchObj.timelineEnd
    })

  useEffect(() => {
    setRangeSelector({ startValue: searchObj.timelineStart, endValue: searchObj.timelineEnd })
  },[searchObj.timelineStart, searchObj.timelineEnd])

  const holder: any = {}
  stocksData?.data.data.forEach((el) => {
    // eslint-disable-next-line no-prototype-builtins
    if (holder.hasOwnProperty(el.stocksDate)) {
      holder[el.stocksDate] =+ holder[el.stocksDate] + el.available
    } else {
      holder[el.stocksDate] = el.available
    }
  })

  const reducedStocksDate = Object.keys(holder).map(el => ({
    stocks: holder[el],
    orderDate: el
  }))

  const timelineData: Array<dataType> | any = useMemo(() =>
    mergeArrayByKey('orderDate',reducedStocksDate, ordersData?.data?.data).map((el) => ({
      orders: el[searchObj.units!],
      stocks: stocksData?.data.data.filter((elem) => elem.stocksDate === el.orderDate).reduce((sum, elem) => sum + elem.available, 0),
      orderDate:el.orderDate
    })), [ordersData, stocksData])

  const handleChangeTimelineParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    window.history.pushState({}, '', `?${historyStr}`)
  }

  const maxOrder = Math.max.apply(null, ordersData?.data.data.map(el => el[searchObj.units!]) || [])
  const maxStock = Math.max.apply(null, Object.keys(holder).map(el => holder[el]))

  const rangeData: Array<dataType> | any = useMemo(() => timelineData.map((el) => ({
    orders: el.orders / (2 * maxOrder),
    stocks: el.stocks / maxStock,
    orderDate: new Date(el.orderDate)
  }
  )), [ordersData, stocksData])

  const [hiddenSeries , setHiddenSeries] = useState({ orders: true, stocks: true })

  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'o' && event.target.value === undefined) {
        event.preventDefault()

        LegendChangeHandle('orders')
      }

      if (event.key === 's' && event.target.value === undefined) {
        event.preventDefault()

        LegendChangeHandle('stocks')
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const LegendChangeHandle = (value) => {
    setHiddenSeries(prev => {
      const { ...rest } = prev
      rest[value] = !prev[value]
      return rest
    })
  }
  const updateVisualRange = (e) => {
    // во избежании перерисовки компонента rangeSelector идет проверка на изменение извне
    if (Object.keys(e)[0] !== '0') {
      const startValue = new Date(e.startValue)
      const endValue = new Date(e.endValue)
      const startValueString =
        new Date(startValue.getTime() + Math.abs(startValue.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      const endValueString = new Date(endValue.getTime() + Math.abs(endValue.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      handleChangeTimelineParams({
        timelineStart: startValueString,
        timelineEnd: endValueString,
      })
      setRangeSelector(e)
    }
  }
  const renderTooltip = (props) => <CustomTooltip x={props} stocksData={stocksData?.data || { data: [], dateFrom: '', dateTo: '' }}/>

  return (
    <>
      {isLoading && <StyledLoader size={60}/>}
      <ChartDetails
        units={searchObj.units!} 
        visualRange={{ 
          startValue: rangeSelector.startValue,
          endValue: rangeSelector.endValue
        }} 
        ordersData={ordersData?.data || { data: [], dateFrom: '', dateTo: '' }} 
        stocksData={stocksData?.data || { data: [], dateFrom: '', dateTo: '' }}/> 
      <Chart
        palette="Harmony Light"
        dataSource={timelineData}
      >
        <Series
          argumentField="orderDate"
          visible={hiddenSeries.orders}
          valueField="orders"
          color="#4CAF50"
          type="spline"
          name="Заказы"
          hoverMode="none"
        >
          <Point hoverMode="none"/>
        </Series>
        <Series
          argumentField="orderDate"
          valueField="stocks"
          visible={hiddenSeries.stocks}
          axis="stocks"
          type="spline"
          color="#3F51B5"
          hoverMode="none"
          name="Остатки">
          <Point hoverMode="none"/>
        </Series>
        <ArgumentAxis
          color="#E0E0E0"
          onVisualRangeChange={updateVisualRange}
          argumentType="datetime"
          discreteAxisDivisionMode="crossLabels"
          visualRange={rangeSelector}
        >
          <Label customizeText={customDate}/>
          <Tick color="#E0E0E0"/>
        </ArgumentAxis>
        <Crosshair enabled={true} horizontalLine={false} dashStyle="longDash">
          <Label visible={false}/>
          <VerticalLine color="#E0E0E0"/>
        </Crosshair>
        <Margin top={24}/>
        <ValueAxis name="orders" showZero={false} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#4CAF50"/>
          </Label>
          <Grid visible={true} color="#E0E0E066"/>
        </ValueAxis>
        <Tooltip
          enabled={true}
          shared={true}
          interactive={false}
          cornerRadius={4}
          border={{ opacity: 0 }}
          color="#FFFFFF00"
          paddingLeftRight={0}
          paddingTopBottom={0}
          shadow={{ blur: 0, offsetY: 0, offsetX: 0, opacity: 0 }}
          contentRender={renderTooltip}
        />
        <ValueAxis position="right" name="stocks" showZero={false} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#3F51B5"/>
          </Label>
          <Grid visible={true} color="#E0E0E066"/>
        </ValueAxis>
        <Legend visible={false}/>
        <ZoomAndPan
          argumentAxis="zoom"
          allowTouchGestures={true}
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="shift"/>
        <CommonSeriesSettings>
          <Point size={6}/>
        </CommonSeriesSettings>
      </Chart>

      <RangeSelector
        dataSource={rangeData}
        value={rangeSelector}
        onValueChange={updateVisualRange}
      >
        <Size height={100} />
        <Scale type="continuous" valueType="datetime" minorTickInterval="day">
          <MinorTick visible={false}/>
          <Marker visible={false}/>
          <MinRange days={1}/>
          <RangeLabel customizeText={customDate} />
        </Scale>
        <SliderMarker
          paddingTopBottom={4}
          paddingLeftRight={8}
          color="#F5F5F5"
          customizeText={customDate}>
          <Font color="rgba(0, 0, 0, 0.6)" size={11} weight={600}/>
        </SliderMarker>
        <Behavior callValueChanged={ searchObj.range !== 2 ? 'onMoving' : 'onMovingComplete'}/>
        <ChartOptions>
          <CommonSeriesSettings type="spline"/>
          <Series visible={hiddenSeries.orders} argumentField="orderDate" valueField="orders" color="#4CAF50"/>
          <Series visible={hiddenSeries.stocks} argumentField="orderDate" valueField="stocks" color="#3F51B5"/>
          <ValueAxis/>
          <ValueAxis />
        </ChartOptions>
      </RangeSelector>
    </>
  )
}

const customDate = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    const stringDate = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
    return (
      `${stringDate.split('-')[2]} ${DateAppearanceSettings[stringDate.split('-')[1]].month}`
    )
  }
  return ''
}

const numberWithSpaces = (x) => x.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const CustomTooltip = ({ x, stocksData }: {x: any, stocksData: AnalyticsStocks}) => {
  const utc = new Date(x.argumentText)
  const stringData = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset()*60000)).toISOString().split('T')[0]

  const warehouses = stocksData.data.filter((el) => el.stocksDate === stringData)
  function compare( a, b ) {
    if ( a.available < b.available ){
      return 1
    }
    if ( a.available > b.available ){
      return -1
    }
    return 0
  }
  const sortedWarehouses = warehouses.sort(compare)
  let otherStocks = 0
  // eslint-disable-next-line array-callback-return
  sortedWarehouses.slice(2).map((el) => {
    otherStocks += el.available
  })

  const orders = x.points.find(el => el.seriesName === 'Заказы')
  const stocks = x.points.find(el => el.seriesName === 'Остатки')
  return (
    <StyledTooltip>
      <Typography sx={{ fontSize: '12px', marginBottom: '12px' }}>{`
        ${stringData.split('-')[2]  }
        ${  DateAppearanceSettings[stringData.split('-')[1]].month},
        ${stringData.split('-')[0]}`}
      </Typography>
      {orders &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
        <StyledDot color="#4CAF50"/>
        <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Заказы</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: '#4CAF50',
            marginLeft: 'auto'
          }}>
          {round(orders?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </Typography>
      </Box>
      }
      {stocks &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
        <StyledDot color="#3F51B5"/>
        <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Остатки</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: '#3F51B5',
            marginLeft: 'auto'
          }}>
          {round(stocks?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </Typography>
      </Box>
      }
      {sortedWarehouses.length > 0 ?
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px', marginLeft: '16px' }}>
          <Typography
            sx={{
              fontSize: '11px',
              color: 'rgba(0, 0, 0, 0.6)',
              marginRight: '10px'
            }}>
            {sortedWarehouses[0].marketplacewarehouseName}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: '500',
              color: '#3F51B5',
              marginLeft: 'auto'
            }}>
            {round(sortedWarehouses[0].available, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box>
        :
        <></>
      }
      {sortedWarehouses.length > 1 ?
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px', marginLeft: '16px' }}>
          <Typography
            sx={{
              fontSize: '11px',
              color: 'rgba(0, 0, 0, 0.6)',
              marginRight: '10px'
            }}>
            {sortedWarehouses[1].marketplacewarehouseName}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: '500',
              color: '#3F51B5',
              marginLeft: 'auto'
            }}>
            {round(sortedWarehouses[1].available, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box>
        :
        <></>
      }
      {otherStocks ?
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px', marginLeft: '16px' }}>
          <Typography
            sx={{
              fontSize: '11px',
              color: 'rgba(0, 0, 0, 0.6)',
              marginRight: '10px'
            }}>
            Другие
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: '500',
              color: '#3F51B5',
              marginLeft: 'auto'
            }}>
            {round(otherStocks, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box> :
        <></>
      }
    </StyledTooltip>
  )
}