import React from 'react'
import { Paper as MuiPaper } from '@mui/material'
import { ActivitiesParams } from 'shared/api/activity/getActivities'
import { useHistory, useLocation } from 'react-router-dom'
import { activityApi } from 'shared/api'
import queryString from 'query-string'
import { stringToNum } from 'shared/lib/utils/stringToNum'
import { stringToBoolean } from 'shared/lib/utils'
import { NewActivityBaseParam } from 'shared/api/activity/model'
import { isNotNil } from 'shared/lib/checkers'
import { getRowsPerPageOptions } from 'shared/ui/components/Table/lib'
import { dialogModel } from 'shared/ui/components/dialog'
import { AddActivityModal } from 'features/analytics/activities'
import { ActivitiesListContext } from 'features/analytics/activities/model/ActivityContext'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'

import { Paper } from './ui/styled'
import { ActivitiesListPage } from './ui/ActivitiesListPage'
import { Header } from './ui/Header'


const ROW_HEIGHT = 78
const HEADER_HEIGHT = 250
const TABLE_CONTROLS = 0
const TABLE_HEADER_FOOTER = 0
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5
export const ActivitiesListPanel = () => {
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const history = useHistory()
  const searchObj: ActivitiesParams = queryString.parse(search)

  const bonusCols = Math.floor(
    (window.innerHeight -
      DEFAULT_ROWS_COUNT * ROW_HEIGHT -
      HEADER_HEIGHT -
      TABLE_CONTROLS -
      TABLE_HEADER_FOOTER) /
    ROW_HEIGHT
  )
  const calculatedRowsPerPage =
    bonusCols > MIN_ROWS_COUNT
      ? DEFAULT_ROWS_COUNT + bonusCols
      : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.pageSize)
    ? parseInt(searchObj.pageSize.toString(), 10)
    : calculatedRowsPerPage
  
  const ActivitiesListParams: ActivitiesParams = {
    involvement: stringToBoolean(searchObj?.involvement),
    campaignStatus: stringToNum(searchObj?.campaignStatus),
    searchString: searchObj?.searchString,
    companyId: stringToNum(searchObj?.companyId),
    marketplaceId: stringToNum(searchObj?.marketplaceId),
    typeId: stringToNum(searchObj?.typeId),
    companymarketplaceId: stringToNum(searchObj?.companymarketplaceId),
    customersActivity: stringToBoolean(searchObj?.customersActivity),
    page: stringToNum(searchObj?.page) || 0,
    pageSize: stringToNum(searchObj?.pageSize) || currentRowsPerPage
  }

  const handleEditParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true }
    )
    history.replace({ search: `?${historyStr}` })
  }

  const { data: activityQuery, isFetching, isLoading } = activityApi.useGetActivitiesQuery(ActivitiesListParams)
  const { mutate: addActivity } = activityApi.useCreateActivityMutation()

  const pageSizes = getRowsPerPageOptions((activityQuery?.data?.count) || 0, currentRowsPerPage)
  
  const handleAddActivity = () => {
    dialogModel.openDialog({
      component: ({ accept, close }) => (
        <AddActivityModal
          accept={accept}
          close={close}
        />
      ),
      onAccept: (activityData: NewActivityBaseParam) => {
        addActivity(activityData, { 
          onSuccess: () => {
            queryClient.refetchQueries({ queryKey: activityApi.getActivitiesQueryKey(ActivitiesListParams) })
            snackActions.info('Кампания добавлена')
          }
        })
      },
    })
  }
  
  return (
    <ActivitiesListContext.Provider
      value={{
        activityQuery: {
          data: activityQuery?.data,
          isFetching,
          isLoading
        },
        handleAddActivity,
        activityParams: ActivitiesListParams,
        handleEditParams,
        currentRowsPerPage,
        pageSizes
      }}>
      
      <Paper component={MuiPaper}>
        <Header/>
        <ActivitiesListPage/>
      </Paper>
      
    </ActivitiesListContext.Provider>
  )
}