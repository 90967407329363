/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AnalyticsDataSale, AnalyticsUnits, SearchParams } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<AnalyticsDataSale>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Date From
   * Дата, начиная с которой возвращаются продажи
   * @format date
   */
    date_from?: string,
  /**
   * Date To
   * Дата, по которую возвращаются продажи
   * @format date
   */
    date_to: string,
  /** @default "currency" */
    units?: AnalyticsUnits,

},
  data: SearchParams
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/analytics/sales`,data, { params: query }).then(res => res.data)


export const usegetSalesSalesPost = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

