import React, { useContext } from 'react'
import { PlugWithAddButton } from 'shared/ui/components'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { ActivitiesListContext, IActivitiesListContext } from '../../../model/ActivityContext'

export const PlugActivityPage = () => {
  const { activityParams } = useContext(ActivitiesListContext) as IActivitiesListContext
  const { page, pageSize, ...params } = activityParams
  const filteredParams = Object.keys(params).filter(el => params[el] !== undefined)
  return (
    <PlugWithAddButton
      title={isEmptyArray(filteredParams) ? 'У вас пока нет кампаний' : 'Ничего не найдено'}
      subTitle={isEmptyArray(filteredParams) ? 'Чтобы начать работу добавьте\nновую кампанию' : 'По данным фильтрам\nкампании не найдены'}
    />
  )
}