import React, { useMemo } from 'react'
import { Box, Button, DialogActions, DialogTitle } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import isEmpty from 'lodash/isEmpty'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { activityApi } from 'shared/api'

import { IconCloseButton } from '../../../../../../shared/ui/components/dialog/ui/styled'
import CloseIcon from '../../../../../../assets/images/closeIcon.svg'
import {
  FormDateTimePicker,
  InputField,
  InputLabel,
  SelectFieldWithAutoComplete
} from '../../../../../../shared/ui/components'
import { StyledField } from '../../../../../../shared/ui/components/StyledField/StyledField'
import { validationSchema } from '../../../lib/validationSchema'


interface AddActivityModalProps {
  close?: () => void;
  accept: (receiveData: any) => void
}
export const AddActivityModal = ({ accept, close }: AddActivityModalProps) => {
  const { data: activityTypesListQuery } = activityApi.useActivityTypesQuery()

  const activityTypesOptions = useMemo(
    () => activityTypesListQuery?.map(getOptions) || [],
    [activityTypesListQuery]
  )
  const onSubmit = (data) => {
    accept(data)
  }

  const validate = useValidationSchema(validationSchema)
  
  return (
    <Box sx={{ width: '500px' }}>
      <IconCloseButton aria-label="close" onClick={close}>
        <img src={CloseIcon} alt="close icon" />
      </IconCloseButton>
      <DialogTitle fontSize={20} whiteSpace="pre-line">
        Добавить процесс
      </DialogTitle>
      <Box px={3} mt={1}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            handleSubmit,
            errors,
            error,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <Box mt={1}>
                <InputLabel
                  label="Вид"
                  required={false}
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="typeId"
                      options={activityTypesOptions}
                      placeholder="Процесс"
                      component={SelectFieldWithAutoComplete}
                      errorAfterTouch={true}
                      fullWidth={true}
                      error={!!errors?.type_id}
                    />
                  </InputWrapper>
                </StyledField>
              </Box>
              <Box mt={4}>
                <InputLabel
                  label="Наименование"
                  required={false}
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="name"
                      placeholder="Наименование"
                      component={InputField}
                      fullWidth={true}
                      errorAfterTouch={true}
                      error={error}
                    />
                  </InputWrapper>
                </StyledField>
              </Box>

              <Box mt={4} display="flex" flexDirection="row" gap="16px">
                <div>
                  <InputLabel
                    label="Начало"
                    required={false}
                  />
                  <StyledField>
                    <InputWrapper>
                      <Field
                        name="fromDate"
                        label="Дата Время"
                        value={values?.fromDate}
                        error={!!errors?.fromDate && touched?.fromDate}
                        errorAfterTouch={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        component={FormDateTimePicker}
                      />
                    </InputWrapper>
                  </StyledField>
                </div>
                <div>
                  <InputLabel
                    label="Конец"
                    required={false}
                  />
                  <StyledField>
                    <InputWrapper>
                      <Field
                        name="toDate"
                        label="Дата Время"
                        value={values?.toDate}
                        error={!!errors?.toDate && touched?.toDate}
                        errorAfterTouch={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        component={FormDateTimePicker}
                      />
                    </InputWrapper>
                  </StyledField>
                </div>
              </Box>

              <DialogActions sx={{
                marginTop: '32px',
                padding: 0,
                marginBottom: '24px'
              }}>

                <Box>
                  <Button
                    onClick={close}
                    color="primary"
                  >
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={false}
                    disabled={!isEmpty(errors)}
                    sx={{ marginLeft: 2 }}
                  >
                    ДОБАВИТЬ
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          )
          }
        />
      </Box>
    </Box>
  )
}