import * as http from 'http'

import { apply } from 'json-logic-js'
import axios, { AxiosTransformer } from 'axios'
import { toCamelCase, toSnakeCase } from 'shared/lib/transform'
import queryString from 'query-string'

import { toSnake } from '../lib/transform/toSnakeCase'
import { RolesNames } from '../types'

/* Observer */
type TokenChangeHandlers = (token: null | string) => void

let tokenChangeHandlers: Array<TokenChangeHandlers> = []

export const tokenChangeSubscribe = (newHandler: TokenChangeHandlers) => {
  tokenChangeHandlers.push(newHandler)
}

export const tokenChangeUnsubscribe = (handlerForRemove: TokenChangeHandlers) => {
  tokenChangeHandlers = tokenChangeHandlers.filter(handler => handler !== handlerForRemove)
}

const tokenChangeBroadcast = (token: string | null) => {
  tokenChangeHandlers.forEach(handler => {
    handler(token)
  })
}
/* Observer End */

const EMPTY_TOKEN = null

let accessToken: null | string = EMPTY_TOKEN

export const clearToken = () => {
  accessToken = EMPTY_TOKEN
  tokenChangeBroadcast(EMPTY_TOKEN)
}

// @ts-ignore
window.clearToken = clearToken

export const setToken = (newToken: string): void => {
  accessToken = newToken
  tokenChangeBroadcast(accessToken)
}

// TODO: убрать, когда полностью избавимся от reguister
export const getToken = (): string | null => accessToken
// TODO: убрать, когда полностью избавимся от reguister
export const getIsAuthExpired = () => {
  if (!accessToken) return true

  const base64String = accessToken.split('.')[1]

  const { exp } = JSON.parse(atob(base64String))
  const currentTime = Math.round( new Date().getTime() / 1000)

  return currentTime >= exp
}

export const hasRoleAccess = (role: RolesNames): boolean => {
  if (!accessToken) {
    return false
  }
  const base64String = accessToken.split('.')[1]
  const { roles } = JSON.parse(atob(base64String))
  return roles.includes(role)
}

export const privilegeCheck = (xPrivilege: Object): boolean => {
  if (!accessToken) {
    return false
  }
  const base64String = accessToken.split('.')[1]
  const { privileges } = JSON.parse(atob(base64String))
  return apply(xPrivilege, { privileges })
}

const appendAuthorizationHeader: AxiosTransformer = (data, headers: { Authorization?: string }) => {
  if (accessToken !== EMPTY_TOKEN) {
    // eslint-disable-next-line no-param-reassign
    headers.Authorization = `Bearer ${accessToken}`
  }

  return data
}

// https://axios-http.com/docs/req_config
export const api = axios.create({
  baseURL: '/api',
  paramsSerializer: (params) => {
    if (params.sort) {
      // eslint-disable-next-line no-param-reassign
      params.sort = toSnake(params.sort)
    }
    return queryString.stringify(toSnakeCase(params), { skipNull: true, skipEmptyString: true })
  },
  transformRequest: [
    // toSnakeCase должен идти перед axios.defaults.transformRequest
    // т к в defaults.transformRequest есть JSON.stringify
    (data) => data instanceof FormData ? data : toSnakeCase(data),
    // https://github.com/axios/axios/blob/master/lib/defaults.js#L39
    ...axios.defaults.transformRequest as AxiosTransformer[],
    appendAuthorizationHeader
  ],
  httpAgent: new http.Agent({ keepAlive: true }),
  transformResponse: [
    // https://github.com/axios/axios/blob/master/lib/defaults.js#L66
    ...axios.defaults.transformResponse as AxiosTransformer[],
    // 1. toCamelCase должен идти после axios.defaults.transformResponse
    // т к в defaults.transformResponse есть JSON.parse
    // 2. Blob не должен обрабатыватся через toCamelCase
    (data) => data instanceof Blob ? data: toCamelCase(data),
  ]
})
