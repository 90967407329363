import React, { useContext } from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ActivitiesListContext, IActivitiesListContext } from 'features/analytics/activities/model/ActivityContext'

export const Header = () => {
  const { handleAddActivity } = useContext(ActivitiesListContext) as IActivitiesListContext
  return (
    <div className="header-container">
      <span className="header-title">Кампании</span>
      <Button
        className="add-activity-button"
        variant="contained"
        startIcon={<AddIcon/>}
        onClick={handleAddActivity}
      >
        Добавить
      </Button>
    </div>
  )
}